.portfolio-link {
  display: block;
  position: relative;
  margin: 0 auto;
  max-width: 400px;

  .caption {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    transition: all ease .5s;

    &::before {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }

    &:hover {
      opacity: 1;
    }

    .caption-content {
      font-size: 15px;
      color: white;
      text-shadow: -1px 0 #ddd, 0 1px #ddd, 1px 0 #ddd, 0 -1px #ddd;
      display: inline-block;
      vertical-align: middle;

      @media screen and (max-width: $sm-break) {
        font-size: 7px;
      }
    }
  }
}

.portfolio-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 25px;
}

.portfolio-cell {
  flex: 0 0 32%;
  margin-bottom: 10px;
}

.portfolio-cell p {
  text-align: center;
}
