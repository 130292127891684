figure {
    display: table;
    margin: 20px;
}

figcaption {
    display: table-caption;
    caption-side: bottom;
    text-align: center;
    font-size: .8em;
}

.image-wrapper img {
    margin: 0;
    padding: 0;
}