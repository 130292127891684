.site-footer {
  @extend %padding-post;
  padding: 0;
  padding-bottom: 15px;
  display: inline-block;
  text-align: center;
  width: 100%;
  color: lighten($text-color, 24%);
  font-size: 0.9em;

  .footer-icons {
    ul {
      list-style: none;
      margin: 0;

      li {
        display: inline;
      }

      a:hover {
        color: darken($link-color, 15%);
      }
    }
  }
}
