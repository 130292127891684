.image-grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.image-grid-wrapper {
    flex: 50%;
    text-align: center;
    margin-bottom: 30px;

    a img {
        display: inline-block;
        max-height: 100vh;
        object-fit: contain;
    }

    .caption {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: $m-break) {
    .image-grid-wrapper {
        flex: 100%;
    }
}